.image-container {
    position: relative;
    display: inline-block; /* Keeps the container size based on the image */

  }
  .transparent-button {
    transform: translate(-50%, -50%); /* Center the button */
    background-color: rgba(0, 0, 0, 0.4); /* Transparent black background */
    color: #fff;
    padding: 0.5rem 3.5rem; /* Adjust padding as needed */
    text-decoration: none;
    border: 2px solid grey;
    border-radius: 10px;
    margin: 8rem;
    width: 100%;
  }
  .homerow {
    position: absolute;
    top: 90%; /* Adjust the top position as needed */
    left: 10%; /* Adjust the left position as needed */
  }
  .transparent-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: 2px solid #017bc1;
    text-decoration: none;
  }
  .homeImages {
    width: 99.2vw;
  }
  .home-content {
    position: relative;

    color: white;
  }
  .buttonColorWhite {
    color: white;
    text-decoration: none;
    width: auto;
    white-space: nowrap; /* Prevents text from wrapping */
    display: inline-block;
  }
  .buttonColorWhite:hover {
    text-decoration: none;
    color: #017bc1;
  }
  .paddingButton {
    padding: 10px 20px;
    width: 300px;
    color: white;
    text-decoration: none;
    border: 1px solid #f1f1f1;
    border-radius: 10px 10px;
    background-color: rgba(0, 0, 0, 0.508);
    display: inline-block;
    text-align: center;
  }
  .buttonDown {
    position: absolute;
    width: 100%;
    top: 70%; /* Adjust as needed */
    left: 50%; /* Adjust as needed */
    transform: translate(-50%, -50%); /* Center the overlay */
    color: white; /* Text color */
    padding: 10px; /* Padding inside the overlay */
    border-radius: 5px; /* Optional: rounded corners */
  }
  .textOnLeft {
    position: absolute;
    font-size: 25px;
    padding: 40px 25px;
    width: 50%;
    top: 40%;
    left: 5%;
    color: white;
    text-decoration: none;
    border: 1px solid #f1f1f1;
    background-color: rgba(0, 0, 0, 0.508);
    border-radius: 10px 10px;
  }
   .homeThreeButtonInside {
    color: black;
    text-decoration: none;
    letter-spacing: 2px;
   }
   .threeButtonsLink {
    text-align: center;
   }
   .threeButtonsLink:hover {
    color: rgb(0, 0, 0);
    text-decoration: none;
   }
   .komColor {
    background-color: rgba(29, 158, 184, 0.75);
    border: 1px solid black;
   }
   .komColor:hover {
    background-color: rgba(29, 158, 184, 0.30);
   }
   .borderleftright:hover {
    background-color: rgba(218, 214, 5, 0.30);
   }
   .borderleftright {
    background-color: rgba(218, 214, 5, 0.75);
    border-left: none;
    border-right: none;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
   }
   @media only screen and (max-width: 991px) {
    .borderleftright {
      background-color: rgba(218, 214, 5, 0.75);
      border-top: none;
      border-bottom: none;
      border-left: 1px solid black;
      border-right: 1px solid black;
     }
     .rightColumnHome {
      padding: 3rem 5rem !important;
    }
     .textOnLeft {
      display: none;
     }
   }
  .home2text {
    text-align: center;
    color: black;
  }
  .homeUnder {
    margin: 50px 0px 0px 0px;
  }
  .leftColumnHome {
    padding: 60px;
    color: black;
  }
  .rightColumnHome {
    padding: 15rem 5rem;
    color: black;
  }
  .boxBottomButton {
    font-size: 0.7rem;
    border: 1px solid #017bc1;
    border-radius: 5px 5px;
    padding: 20px 40px;
    color: #017bc1;
  }
  .boxBottomButton:hover {
    font-size: 0.7rem;
    border: 1px solid white;
    color: white;
    border-radius: 5px 5px;
    padding: 20px 40px;
    text-decoration: none;
    background-color: #017bc1;
  }
  .boxButton {
    border: 1px solid black;
    padding: 20px 40px;
    border-radius: 5px 5px;
  }
  .boxButton i {
    font-size: 30px;
    margin-right: 15px;
  }
  .homeLeftImg {
    width: 100%;
  }
  .blueevolution {
    display: flex;
  }
  .bluerowspace {
    margin-bottom: 7rem;
    display: flex;
    align-items: flex-end;
  }
  .blueev-main {
    padding: 0%;
  }
  .imageside {
    padding: 0%;
  }
  .infotext {
  padding: 8rem 5rem 7rem 10rem;
  }
  .infotextt {
    padding: 8rem 5rem 0rem 10rem;
  }
  .infootext {
    padding-top: 5rem;
  }
  .col-lg-4{
    padding: 2rem;
    text-decoration: none;
  }
  .col-lg-4:hover{
    text-decoration: none;
  }
  .colored-text{
    color: #017bc1;
  }
  .col-lg-6 > p {
    text-align: start;
  }
  .pagesImages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .kommerlingTypes {
    width: 70%;
  }
  .whiteText {
    color: black;
    text-decoration: none;
  }
  .whiteText:hover {
    color: red;
    text-decoration: none;
  }
  .bigphoto {
    max-width: 312px;
  }
  .salamanderphoto {
    width: 100%;
  }
  h2 {
    margin-bottom: 50px;
  }
  .greendowntext {
    display: flex;
  }
  .greenText {
    display: flex;
    align-self: flex-end;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .containerroletna {
    position: relative;
    text-align: center;
    width: 100%;
    max-width: 600px; /* Adjust the maximum width as needed */
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  /* Styling for the image */
  .containerroletna img {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Add border-radius for rounded corners */
  }

  /* Styling for the centered text */
  .centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff; /* Adjust text color as needed */
    font-size: 24px; /* Adjust font size as needed */
    font-weight: bold; /* Adjust font weight as needed */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.308); /* Add text shadow for better visibility */
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency (last parameter) as needed */
    border-radius: 8px; /* Match the border-radius of the image */
  }
  .kommerlingRowMargin {
    margin-top: 10%;
  }
  .kommerlingRowMarginLess {
    margin-top: 5%;
  }
  .komBottomMargin {
    margin-bottom: 25%;
    padding: 5% 20%;
  }
  .textMarginTop70mm {
    margin-top: 50px;
  }
  .imageFloat {
    width: 400px;
    float: left;
  }
  .mmInfoBrosuri, .mmInfoVazno {
    margin-left: 3%;
    border: 2px solid #017bc1;
    padding: 5px 30px;
    border-radius: 8px;
    margin-top: 10px;
  }
  .mmInfoBrosuri {
    background-color: white;
    color: #017bc1;
    margin-top: 10px;
    outline: none;
  }
  .mmInfoVazno {
    background-color: #017bc1;
    color: white;
    outline: none;
  }
  .buttonBottom {
    margin-top: 3%;
    margin-left: 5%;
  }
  .mmInfoBrosuri:hover {
    background-color: #017bc1;
    color: white;
  }
  .mmInfoVazno:hover {
    background-color: white;
    color: #017bc1;
  }
  .mmDetails {
    margin-top: 5%;
  }
  .mmDetails li {
    margin: 1% 0%;
  }
  .mmSubLinks {
    color: #017bc1;
    text-decoration: none;
  }
  .mmSubLinks:hover {
    color: rgba(29, 158, 184, 0.782);
    text-decoration: none;
  }
  .pagesImages p {
    text-align: start;
  }
  .textStartRow {
    padding: 0;
    margin: 0;
   /* position: absolute; */
  }
 /* .pageImagesText {
    position: relative;
    top: 0;
  }*/
  .textStartCol {
    padding: 0;
    margin: 0;
  }
  .mm88Padding {
    padding: 0 8% ;
  }
  .infoPadTop {
    margin-top: 30px;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    filter: invert(1);
  }
  .daskaSize {
    width: 400px !important;
    margin-left: 30px;
  }
  .aluRoletna {
    width: 400px;
  }
  .contactWrapper {
    padding: 100px;
  }
  .custom-file-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px; /* Adjust the height as needed */
    border: 2px dashed #ccc;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    color: #555;
    position: relative;
    transition: background-color 0.2s ease;
  }
  
  .custom-file-upload:hover {
    background-color: #f0f0f0;
  }
  
  .custom-file-upload i {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .custom-file-upload.dragover {
    background-color: #e0e0e0;
    border-color: #000;
  }
  #file {
    display: none; /* Hide the default file input */
  }
  .formDivLeft {
    padding: 0%;
    box-shadow: 
    0 4px 8px rgba(0, 0, 0, 0.1),   /* Light shadow */
    0 8px 16px rgba(0, 0, 0, 0.1),  /* Medium shadow */
    0 12px 24px rgba(0, 0, 0, 0.1); /* Darker shadow */
  }
  .contactImg {
    width: 100%;
    box-shadow: 
    0 4px 8px rgba(0, 0, 0, 0.1),   /* Light shadow */
    0 8px 16px rgba(0, 0, 0, 0.1),  /* Medium shadow */
    0 12px 24px rgba(0, 0, 0, 0.1); /* Darker shadow */
  }
  .contactPaddingAll {
    padding: 4% 11%;
  }
  .paddingForm {
    padding: 6% 3%;
  }
  .submitButtonSize {
    margin-top: 10px;
    width: 100%;
  }
  .iconTopContact {
    padding: 5% 15%;
  }
  .borderToIcons {
    border-radius: 6px 6px 6px 40px;
    box-shadow: 
    0 4px 8px rgba(0, 0, 0, 0.1),   /* Light shadow */
    0 8px 16px rgba(0, 0, 0, 0.1),  /* Medium shadow */
    0 12px 24px rgba(0, 0, 0, 0.1); /* Darker shadow */
    text-align: center;
    padding: 40px 0px 10px 0px;
  }
  .borderToIcons i {
    font-size: 30px;
    color: #017bc1;
  }
  .borderToIcons p {
    font-size: 13px;
  }




  
  .project-implementation-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f4f8;
    font-family: Arial, sans-serif;
  }
  
  .titlee {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #333;
  }
  
  .contentt {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 3%;
    padding: 3%;
  }
  
  .stepss {
    width: 55%;
    margin-top: 5%;
  }
  
  .stepp {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  
  .iconn {
    font-size: 32px;
    color: black;
    margin-right: 4%;
    margin-top: 2%;
    padding: 20px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-self: center;
  }
  
  .text-contentt {
    max-width: 80%;
  }
  
  .stepp h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: black;
  }
  
  .stepp p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  
  .image-containerr {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .main-imagee {
    width: 70%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 1024px) {
    .contentt {
      flex-direction: column;
      align-items: center;
    }
  
    .stepss {
      width: 90%;
    }
  
    .image-containerr {
      width: 80%;
      margin-top: 30px;
    }
  }
  
  @media (max-width: 768px) {
    .text-contentt {
      max-width: 100%;
    }
  
    .iconn {
      font-size: 28px;
    }
  
    .stepp h3 {
      font-size: 18px;
    }
  
    .stepp p {
      font-size: 14px;
    }
  }
