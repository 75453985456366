.blog-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .blog-post {
    width: 80%;
    margin: 0 auto 20px auto;
    border: 1px solid #ccc;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .blog-post:hover {
    transform: scale(1.02);
  }
  
  .first-post {
    width: 100%;
    background-color: #f9f9f9;
    margin-bottom: 40px;
  }
  
  .blog-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .blog-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .blog-date {
    font-size: 14px;
    margin-bottom: 20px;
    color: #666;
  }
  
  .blog-content {
    font-size: 16px;
    line-height: 1.6;
    color: #444;
  }
  
  .blog-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-left: 20px;
  }
  
  .read-more-button, .show-more-button {
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #017bc1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .read-more-button:hover, .show-more-button:hover {
    background-color: #0056b3;
  }
  
  .blog-posts-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .other-post {
    width: calc(33.333% - 20px);
    margin: 0;
  }
  
  /* Responsive styles */
  @media (max-width: 1024px) {
    .blog-content-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .blog-image {
      width: 100%;
      height: auto;
      margin-left: 0;
      margin-top: 20px;
    }
  
    .other-post {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .blog-content-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .blog-image {
      width: 100%;
      height: auto;
      margin-left: 0;
      margin-top: 20px;
    }
  
    .other-post {
      width: 100%;
    }
  }
  