.navbar {
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0% 0% 0% 1%;
  height: 4.5rem;
  background-color: #f1f1f1;
  margin: 0;
}
.logoimage {
  width: 167px;
}
.far-leftside{
  margin-right: auto;
}
.logo {
  margin: 0;
}
.center-div {
  flex-grow: 1;
  text-align: center;
}
.navbar-collapse {
  background-color: #f1f1f1;
}
.navbar-toggler:focus {
  outline: none;
}
.navbar-toggler-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-item {
  margin: 0rem 1rem;
}
.nav-link {
  position: relative;
  border-bottom: 2px solid transparent;
  transition: border-color 0.4s ease;
  color: #000;
}
.nav-link:hover {
  color: #017bc1;
  border-bottom: 2px solid #017bc1;
}
.far-rightside {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.search-container,
.language-flag {
  margin-left: 30px;
  height: 18px;
}
.language-flag {
  margin-top: 0%;
  padding-top: 0%;
}
/* Style the dropdown menu */
.dropdown-menu {
  background-color: #f1f1f1;
  border: none;
  border-radius: 0%;
  display: none; /* Initially hidden */
  position: absolute;
  top: 100%; /* Position below the parent menu item */
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdown-menu li {
  padding: 10px;
  border: 5px solid red;
}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-item:hover {
  color: #017bc1;
}
.transparent-dropdown .dropdown-menu {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.dropdown-item {
  padding-right: 6rem;
}
.dropdown-submenu {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 250px;
}
.something {
    display: none;
    position: absolute;
    top: 0%;
    left: -1%;
    background-color: #f1f1f1;
    min-width: 160px;
    z-index: 1;
}
.submenublock:hover .something {
  display: block;
  background-color: #f1f1f1;
  color: #017bc1;
}
.transparent-dropdown .dropdown-menu a {
  color: #000;
}
 .search-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#search-input {
  width: 0;
  opacity: 0;
  transition: width 0.3s, opacity 0.3s;
}