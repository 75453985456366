.footer {
    background-color: #000;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    padding: 5rem 0rem;
  }
  .footerItems {
    margin-bottom: 0.75rem;
  }
  .iconcolor {
    color: white;
  }
  .footerItems:hover { 
    color: #017bc1;
  }
  .footerIcon {
    margin-right: 0.75rem;
  }
  .textwhite:hover {
    text-decoration: none;
  }
  .allRightsRes{
    display: flex;
    justify-content: center;
    padding: 1rem 1rem;
    color: white;
    font-size: 0.9rem;
    font-weight: 300;
    background-color: #1E1E1E;
    text-align: center;
  }
  @media only screen and (max-width: 500px) {
    .footer {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    }