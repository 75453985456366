body {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Roboto', sans-serif;
}
@media only screen and (max-width: 556px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .leftoffooter {
    margin-bottom: 5%;
  }
  .infotext {
    padding: 8rem 2rem 0rem 2rem !important;
    }
    h2 {
      font-size: 1.5rem;
    }
    .transparent-button {
      top: 80% !important; /* Adjust the top position as needed */
    }
}
  @media only screen and (max-width: 991px) {
      .langselect {
        padding-bottom: 2rem;
      }
      .language-flag {
        margin-left: 0%;
      }
      .something {
        left: 100%;
      }
      .dropdown-submenu {
        display: block;
        width: 100%;
      }
      .col-lg-2 {
        padding: 50px;
      }
      .komBottomMargin {
        padding: 5% 10% !important;
      }
      .imageFloat {
        width: 270px !important;
      }
}
@media only screen and (min-width: 992px) and (max-width: 1127px) {
  .nav-item {
    margin: 0rem 0.4rem;
  }
}
@media only screen and (min-width: 1127px) and (max-width: 1398px) {
  .nav-item {
    margin: 0rem 0.2rem;
  }
}
.btn {
 background-color: #017bc1;
 border: 2px solid #017bc1;
}
.aboutInfo {
  padding: 10%;
}
.backgroundKomer {
  background-color: rgba(29, 158, 184, 0.312);
  padding: 5%;
  border-radius: 5px 5px;
}
.fixedsizeoftext {
  padding: 10px;
  border: 1px solid rgba(138, 138, 138, 0.563);
  border-radius: 5px; 
  max-height: 70px;
  resize: none;
}
.formMargin {
  margin-bottom: 10px;
}
.mainappcontent {
  margin-top: 72px;
}