#popup {
  display: none;
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 900ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  width: auto; /* Allow the content to determine the width */
  max-width: 500px; /* Set a maximum width if needed */
  height: auto; /* Allow the content to determine the height */
  max-height: 100vh; /* Set a maximum height if needed */
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  outline: none;
  overflow: auto; /* Enable scrolling if the content is too long */
}

.ReactModal__Content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #000;
  background: none;
  border: none;
}

.ReactModal__Content .close-button:hover {
  color: #ff0000;
}

.submitpopup {
  width: 100%;
  border: 2px solid #017bc1;
  border-radius: 5px;
  background-color: #017bc1;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.termsConditionsFont {
  font-size: 70%;
}
.popupwidth {
  width: 100%;
}
.popUpItems {
  color: black;
  margin-bottom: 0.3rem;
}
.popUpItems:hover { 
  color: rgb(29,157,184);
}
.zaz {
  padding: 10px;
  border: 1px solid rgba(138, 138, 138, 0.563);
  border-radius: 5px;
  max-height: 70px;
  resize: none;
}
.smallScreenPopUp {
  margin-bottom: 20px;
}
@media only screen and (max-width: 500px) {
  .wrapper, form {
    font-size: 0.8rem; }
    .smallScreenPopUp {
      font-size: 1.2rem;
      padding-bottom: 0px !important;
      margin-bottom: 15px !important;
    }
  }